import type { OnInit } from '@angular/core';
import { Compiler, Component, Inject, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { XccEnvironment } from '@xcc-client/services';
import { Brand } from '@xcc-models';

@Component({
  selector: 'xcc--default-checkout',
  templateUrl: './default.component.html',
})
export class DefaultComponent implements OnInit {
  @ViewChild('default', { read: ViewContainerRef })
  public default: ViewContainerRef;

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
  ) {}

  async ngOnInit(): Promise<void> {
    const brand = this.xccEnv.brand;
    switch (brand.toLocaleUpperCase()) {
      case Brand.IDS:
        await import(
          /* webpackChunkName: "ids-default-module" */
          `../../../brands/ids/src/app/default/default.module`
        ).then(({ IdsDefaultModule }) => this.compileLazyModule(IdsDefaultModule));
        break;
      case Brand.DEC:
        await import(
          /* webpackChunkName: "dec-default-module" */
          `../../../brands/dec/src/app/default/default.module`
        ).then(({ DecDefaultModule }) => this.compileLazyModule(DecDefaultModule));
        break;
      case Brand.AA:
        await import(
          /* webpackChunkName: "aa-default-module" */
          `../../../brands/aa/src/app/default/default.module`
        ).then(({ AaDefaultModule }) => this.compileLazyModule(AaDefaultModule));
        break;
      case Brand.ACE:
        await import(
          /* webpackChunkName: "ace-default-module" */
          `../../../brands/ace/src/app/default/default.module`
        ).then(({ AceDefaultModule }) => this.compileLazyModule(AceDefaultModule));
        break;
      case Brand.AARP:
        await import(
          /* webpackChunkName: "aarp-default-module" */
          `../../../brands/aarp/src/app/default/default.module`
        ).then(({ AarpDefaultModule }) => this.compileLazyModule(AarpDefaultModule));
        break;
      case Brand.PA:
        await import(
          /* webpackChunkName: "pa-default-module" */
          `../../../brands/pa/src/app/default/default.module`
        ).then(({ PaDefaultModule }) => this.compileLazyModule(PaDefaultModule));
        break;
      case Brand.INS:
        await import(
          /* webpackChunkName: "pa-default-module" */
          `../../../brands/ins/src/app/default/default.module`
        ).then(({ InsDefaultModule }) => this.compileLazyModule(InsDefaultModule));
        break;
      default:
        break;
    }
  }

  private compileLazyModule(module: any): void {
    this.compiler.compileModuleAsync(module).then((moduleFactory) => {
      // Create a moduleRef, resolve an entry component, create the component
      const moduleRef = moduleFactory.create(this.injector);
      const componentFactory = (moduleRef.instance as any).resolveComponent();
      this.default.createComponent(componentFactory);
    });
  }
}
