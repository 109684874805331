export interface IterableSubscribeDataFields {
  brandId: string;
  firstName: string;
  lastName: string;
  email: string;
  courseState: string;
  courseType?: CourseTypes;
  checkoutUrl: string;
  signupSource: string;
  userType: string;
  productId: string;
  productTitle: string;
  customerPrice: number;
  strikePrice: number;
  coupons?: string[];
  products?: string[];
  // Purchase as parent valuesß
  name?: string;
  segment?: string;
  isParent?: boolean;
}

export interface IterableListSubscribeRequest {
  listId: number;
  subscribers: Array<IterableSubscriber>;
}

interface IterableSubscriber {
  email: string;
  dataFields: IterableSubscribeDataFields;
}

export enum CourseTypes {
  DIP = 'DIP',
  ADULT = 'ADULT',
  TEEN = 'TEEN',
  MATURE = 'MATURE',
  BTW = 'BTW',
  AA = 'AA-RE',
  ACE = 'ACE-DE',
  DD = 'DD',
  DE = 'DE',
  IN_DD = 'IN_DD',
  AMI = 'AMI',
  ADE = 'ADE',
  PTDE = 'PTDE',
  PA = 'RE-PREP-SP-PA',
  MDS = 'MDS',
  PT = 'PT',
  INS = 'ACE-INS',
}

// Used for special cases where product should have a different course type based on the course key
export enum CourseKey {
  DEC_TX_PTDE = 'DEC.TX.PTDE',
}

export interface IterableSuccessfulResponse {
  code?: string;
  msg?: string;
  params?: any; // eslint-disable-line
}

export interface IterableTrack {
  brandId: string;
  dataFields: any; // eslint-disable-line
  email: string;
  eventName: string;
}

export interface IterableTrackPurchase extends IterableTrack {
  dataFields: {
    orderId: string;
    coupon: string;
    platform: string;
    purchasePrice: number;
    productId: string;
    productKeyList: string;
  };
}

export interface IterableUpdateUser {
  brandId: string;
  dataFields: any; // eslint-disable-line
  email: string;
}

export interface IterableUpdateUserPurchase extends IterableUpdateUser {
  dataFields: {
    aceId: string;
    email: string;
    firstName: string;
    lastName: string;
    isPurchased: boolean;
    userType: string;
    platform: string;
  };
}
// Used for iterable list ids object
export interface IterableListIds {
  DE?: number;
  DIP?: number;
  DD?: number;
  BTW?: number;
  PTDE?: number;
  ADE?: number;
  AMI?: number;
}
