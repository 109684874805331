import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Data } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { PrepaidCodeService } from '@xcc-client/services';
import type { PageConfig, XccConfig } from '@xcc-models';
import type { Observable } from 'rxjs';
import { Subject, map, takeUntil } from 'rxjs';
import { ShoppingCartService } from '../../shopping-cart/shopping-cart.service';
import { PaymentFormService } from './payment-form.service';

import type { FormGroup } from '@angular/forms';
import { PaymentContinueService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/payment-continue.service';
import { PaymentStripeService } from '@xcc-client/shared/components/xcc-continue-panel/xcc-continue-panel/payment-stripe.service';
import { XgritPurchaseService } from '../../xcc-continue-panel/xcc-continue-panel/xgrit-purchase.service';

@Component({
  selector: 'xcc-stripe-payment-form',
  templateUrl: './stripe-payment-form.component.html',
})
export class StripePaymentFormComponent implements OnInit, OnDestroy {
  pageConfig: PageConfig;
  xccConfig: XccConfig;

  private paymentForm_: FormGroup;

  private ngUnsubscribe = new Subject<void>();

  isPrepaidCode: boolean;
  isFreePayment = true;

  @Input() hiddenPayment: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly paymentFormService: PaymentFormService,
    private readonly stripeService: PaymentStripeService,
    private codeService: PrepaidCodeService,
    private readonly cartService: ShoppingCartService,
    readonly paymentContinueService: PaymentContinueService,
    private readonly xgritPurchaseService: XgritPurchaseService,
  ) {}

  ngOnInit(): void {
    this.route.data
      .pipe(
        map((routeData: Data) => routeData.xccConfig),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(this.onConfigurationChanged);

    this.paymentForm_ = this.paymentFormService.formGroup;

    this.codeService.currentCodeStatus.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status) => {
      this.isPrepaidCode = status;
    });

    this.cartService.totalPriceDollarsChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((total) => {
      this.isFreePayment = total === 0.0;
    });
  }

  get isDupePurchase(): boolean {
    return this.xgritPurchaseService.isDupePurchase.getValue();
  }

  isFreePaymentOrPrepaid() {
    // Case: Prepaid code but no freePayment (cost > $0.00) -> Don't hide payment!
    if (this.isPrepaidCode && !this.isFreePayment) {
      return false;
      // Case: this.isPrepaidCode and this.isFreePayment (cost === $0.00) -> Hide Payment
    } else if (this.isPrepaidCode && this.isFreePayment) {
      return true;
      // Case: this.isFreePayment true (cost === $0.00) -> Hide Payment
    } else if (!this.isPrepaidCode && this.isFreePayment) {
      return true;
    }
  }

  ngOnDestroy(): void {
    // Reset the payment form to its initial state
    this.paymentForm.reset();

    // Notify subscribers to complete and clean up any subscriptions
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Getter for the cardHeading, providing the heading for the card section in the UI.
   * @returns {string} The heading for the card section.
   */
  get cardHeading(): string {
    return 'Payment';
  }
  /**
   * Getter for the paymentFormGroup, providing access to the form group from the payment form service.
   * @returns {FormGroup} The form group representing the payment form.
   */
  get paymentFormGroup(): FormGroup {
    return this.paymentFormService.formGroup;
  }

  /**
   * Getter for the paymentForm, providing access to the underlying payment form.
   * @returns {FormGroup} The form group representing the payment form.
   */
  get paymentForm(): FormGroup {
    return this.paymentForm_;
  }

  get showError(): Observable<boolean> {
    return this.paymentContinueService.showErrors;
  }

  get loginMessage(): string {
    return this.xccConfig.pageConfig.appLogin;
  }

  /**
   * Getter for the error message to display to the user.
   * If there is an error message from the Stripe service, it is returned.
   * If not, a default error message is returned.
   * @returns {string} The error message to display.
   */

  get errorMessage(): string {
    // Check if there is a specific error message from the Stripe service
    if (this.stripeService.errorMessage) {
      return this.stripeService.errorMessage;
    }
    // Avoid showing an error message if the user is attempting a duplicate purchase
    if (!this.isDupePurchase) {
      return;
    }
    // Return a default error message if no specific error message is available
    return "We're not able to process your transaction. Please check that all info is accurate or use a different credit/debit card.";
  }

  /**
   * Callback function invoked when the configuration changes.
   * Updates the internal configuration properties based on the provided XccConfig.
   * @param {XccConfig} xccConfig - The new XccConfig containing updated configuration information.
   * @returns {void}
   */
  onConfigurationChanged = (xccConfig: XccConfig) => {
    // Update the xccConfig property with the new configuration
    this.xccConfig = xccConfig;

    // Update the pageConfig property with the page configuration from the new XccConfig
    this.pageConfig = xccConfig.pageConfig;
  };
}
